import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import posthog from 'posthog-js';

if (environment.production) {
    enableProdMode();
}

posthog.init(environment.postHogKey, {
    api_host: environment.postHogHost,
    person_profiles: 'identified_only',
    capture_pageview: true,
    capture_pageleave: false,
});

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
    constructor(private authenticationSandbox: AuthenticationSandbox, private navigationSandbox: AppNavigationSandbox) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authenticationSandbox.isLoggedIn$.pipe(
            map((isLoggedIn) => {
                if (isLoggedIn) {
                    return true;
                } else {
                    this.navigationSandbox.goToLogin().then();
                    return false;
                }
            })
        );
    }
}

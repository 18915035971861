<app-alert [icon]="icon" [context]="context" [iconContext]="context" [iconAppearance]="iconAppearance">
    <h6 class="u-font-weight-bold u-margin-bottom-half">{{ stateLabel + '.TITLE' | translate }}</h6>
    <p class="u-margin-bottom-half">{{ stateLabel + '.DESCRIPTION' | translate }}</p>
    <p class="u-margin-bottom-half" *ngIf="move.moveStates.meterComplete">{{ stateLabel + '.DESCRIPTION_2' | translate }}</p>
    <a
        id="mailToCta"
        smvd-ui-button
        [appearance]="buttonAppearance.Link"
        class="u-padding-none u-color-text u-h-auto"
        [href]="mailContent | uriSchema: uriSchemaType.MailTo"
        *ngIf="move.moveStates.meterComplete"
        >{{ baseLabel + '.CTA' | translate }}</a
    >
</app-alert>

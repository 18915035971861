import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LocationProfile } from '@smooved/ui';
import { translator } from '../../../../smooved-match/src/i18n/server';
import { ExternalInfluencer } from '../company-page';
import { Language } from '../enums';
import { RealEstateAgency } from '../interfaces';
import { NumberUtils } from '../utils';
import { MetaGroupService } from './meta-group.service';
import { BaseMetaService } from './meta.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MetaLocationService extends BaseMetaService {
    constructor(
        protected readonly meta: Meta,
        protected readonly title: Title,
        protected readonly translateService: TranslateService,
        @Inject(DOCUMENT) protected document: Document,
        rendererFactory: RendererFactory2,
        private readonly metaGroupService: MetaGroupService
    ) {
        super(meta, title, document, rendererFactory);
    }

    public addMetaData(
        locationProfile: LocationProfile,
        externalInfluencer: ExternalInfluencer,
        language: Language,
        description: string,
        title: string,
        externalInfluencerUri: string,
        isProduction: boolean
    ): void {
        const metaData = this.buildMeta(
            locationProfile,
            externalInfluencer,
            language,
            description,
            title,
            externalInfluencerUri,
            isProduction
        );
        this.addMetaTags(metaData);
    }

    public addAlternateLanguageLinks(
        externalInfluencerUri: string,
        locationProfile: LocationProfile,
        externalInfluencer: ExternalInfluencer
    ): void {
        this.cleanUpOldElements('link[rel="alternate"]');
        Object.values(Language).forEach((lang) => {
            const href = this.getLocationUrl(locationProfile, externalInfluencer, externalInfluencerUri, lang);
            this.addAlternateLangLink(lang, href);
        });
    }

    protected buildMeta = (
        locationProfile: LocationProfile,
        externalInfluencer: ExternalInfluencer,
        language: Language,
        description: string,
        title: string,
        externalInfluencerUri: string,
        isProduction: boolean
    ): {
        [key: string]: string;
    } => {
        const url = locationProfile?.slug;
        const completeUrl = this.getLocationUrl(locationProfile, externalInfluencer, externalInfluencerUri, language);

        const metaProps: object = {
            'og:description': description,
            'og:locale': language,
            'og:type': 'website',
            'twitter:description': description,
            ...(title ? { 'og:title': title, 'twitter:title': title } : {}),
            ...(url ? { 'og:site_name': completeUrl, 'og:url': completeUrl, 'twitter:url': completeUrl } : {}),
        };

        return {
            ...metaProps,
        };
    };

    private getLocationUrl(
        locationProfile: LocationProfile,
        externalInfluencer: ExternalInfluencer,
        externalInfluencerUri: string,
        lang: Language
    ): string {
        const locationTranslation = translator.translateRoute(lang, 'location');
        const splittedSlug = locationProfile.slug.split('/');
        const groupUrl = this.metaGroupService.getUrl(externalInfluencerUri, externalInfluencer, lang);
        return `${groupUrl}/${locationTranslation}/${splittedSlug[1]}`;
    }

    public getTitle = (location: RealEstateAgency): string => {
        if (!location?.name) return null;
        if (!NumberUtils.isNumber(location.reviewScore?.score)) return 'DEFAULT_META.TITLE_REALTORS_WITHOUT_SCORE';
        return 'DEFAULT_META.TITLE_REALTORS_WITH_SCORE_LOCATION';
    };
}

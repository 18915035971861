<app-menu>
    <app-menu-item
        goTo="analytics/overview"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.Leaderboard"
    >
        {{ 'METRICS.MENU.OVERVIEW' | translate }}
    </app-menu-item>
    <app-menu-item
        goTo="analytics/inputs"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.Added"
    >
        {{ 'METRICS.MENU.INPUT' | translate }}
    </app-menu-item>
    <app-menu-item
        goTo="analytics/confirmations"
        appearance="button"
        (click)="onMenuClick()"
        [svgSize]="menuItemIconSize.Md"
        [svg]="svgIllustration.CheckOutline"
    >
        {{ 'METRICS.MENU.CONFIRMATION' | translate }}
    </app-menu-item>
</app-menu>

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    NavigationCancel,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
} from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { Observable, Subscription } from 'rxjs';
import { first, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AdminOrRealEstateAgentGuard implements CanLoad, CanActivate {
    private event: Subscription;
    constructor(
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.checkAccess();
    }

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAccess();
    }

    private checkAccess(): Observable<boolean> {
        this.setRouterEventsSubscription();
        return this.authenticationSandbox.isRealEstateAgentOrAdmin$.pipe(
            tap((isRealEstateAgentOrAdmin) => {
                if (isRealEstateAgentOrAdmin) this.event?.unsubscribe(); // it's validated, no need to subscrible to cancel events;
            }),
            take(1)
        );
    }

    private setRouterEventsSubscription(): void {
        this.event = this.router.events
            .pipe(
                first((_) => _ instanceof NavigationCancel),
                take(1)
            )
            .subscribe((event: NavigationCancel) => void this.navigationSandbox.goToRealEstateAgentLogin(event.url));
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { MenuItemService } from './menu-item.service';

@Pipe({
    name: 'menuItemRoute',
})
export class MenuItemRoutePipe implements PipeTransform {
    constructor(private readonly menuItemService: MenuItemService) {}
    transform(value: any): any {
        return this.menuItemService.routeTranslate(value);
    }
}

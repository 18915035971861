import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureScope, FeatureScopeConfig, FeatureScopeService, LogicalOperation } from '@smooved/core';
import { ReferrerSandbox } from '@app/referrer/sandboxes/referrer.sandbox';

@Injectable({
    providedIn: 'root',
})
export class FeatureScopeForReferrerGuard implements CanActivate {
    constructor(
        private readonly featureScopeService: FeatureScopeService,
        private readonly referrerSandbox: ReferrerSandbox,
        private readonly navigationSandbox: AppNavigationSandbox
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        return this.checkAccess(route);
    }

    private checkAccess(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const { scopes = [], operator = LogicalOperation.And } = (route.data.featureScopeConfig as FeatureScopeConfig) || {};
        if (![...scopes]?.length) return true;

        return this.featureScopeService
            .hasAccessToFeatureScopes(this.referrerSandbox.getRealEstateAgentId(), scopes as FeatureScope[], operator)
            .pipe(
                map((hasAccess) => {
                    if (hasAccess) return true;
                    this.navigationSandbox.goToHome();
                    return false;
                })
            );
    }
}

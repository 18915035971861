import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { CustomMessageTemplate } from '../interfaces/custom-message-template';
import { communication } from './communcation.constants';
import {
    clearCustomMessageTemplates,
    getCustomMessageTemplatesFailure,
    getCustomMessageTemplatesSuccess,
    loadCustomMessageTemplates,
} from './communication.actions';

export interface CommunicationState {
    loading: boolean;
    templates: CustomMessageTemplate[];
}

const initialCommunicationState: CommunicationState = {
    loading: false,
    templates: null,
};

const reducer = createReducer(
    initialCommunicationState,

    on(loadCustomMessageTemplates, (state, _) => ({
        ...state,
        loading: true,
    })),

    on(getCustomMessageTemplatesSuccess, (state, action) => ({
        ...state,
        loading: false,
        templates: action.templates || [],
    })),

    on(getCustomMessageTemplatesFailure, (state, _) => ({
        ...state,
        loading: false,
        templates: null,
    })),

    on(clearCustomMessageTemplates, (state, _) => ({
        ...state,
        templates: null,
    }))
);

export const communicationReducer = (state: CommunicationState | undefined, action: Action): CommunicationState => reducer(state, action);
const getCommunicationState = createFeatureSelector<CommunicationState>(communication);
export const getTemplatesState = createSelector(getCommunicationState, (state) => state?.templates);
export const getLoadingState = createSelector(getCommunicationState, (state) => state?.loading);

import { Types } from 'mongoose';
import { ObjectUtils } from './object-utils';

export class DbUtils {
    public static getStringId(obj: any): string | null {
        if (Types.ObjectId.isValid(obj as Types.ObjectId)) {
            return obj.toString();
        } else if (obj?.hasOwnProperty('id') || !!obj?.id) {
            return obj.id?.toString();
        } else if (obj?.hasOwnProperty('_id') || !!obj?._id) {
            return obj._id?.toString();
        } else {
            return null;
        }
    }

    public static isDocument(doc: any): boolean {
        return ObjectUtils.isObject(doc) && !!doc._id;
    }
}

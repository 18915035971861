import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RealEstateAgentProvidersModule } from '../real-estate-agent-providers.module';
import { getBadgeCounts } from '../state/real-estate-agents.actions';
import { RealEstateAgentState } from '../state/real-estate-agents.reducer';

const refreshAfterMinutes = 5;

@Injectable({ providedIn: RealEstateAgentProvidersModule })
export class BadgeCountActivator implements CanActivate, CanDeactivate<unknown> {
    private destroy$ = new Subject<boolean>();

    constructor(private readonly store: Store<RealEstateAgentState>) {}

    public canActivate(): boolean {
        timer(0, 1000 * 60 * refreshAfterMinutes)
            .pipe(takeUntil(this.destroy$))
            .subscribe(this.loadBadgeCounts);
        return true;
    }

    public canDeactivate(): boolean {
        this.destroy$.next(true);
        this.destroy$.complete();
        return true;
    }

    private loadBadgeCounts = (_): void => {
        this.store.dispatch(getBadgeCounts());
    };
}

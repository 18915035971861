import Quill from 'quill';

export class QuillUtils {
    public static addMatcherPastePlainText(quill: Quill): void {
        quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            delta.ops = delta.ops.map((op) => ({ insert: op.insert }));
            return delta;
        });
    }
}

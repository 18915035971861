import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { imagesUri } from '@app/gift/constants/uri.constants';
import { OrderGift } from '@app/order/interfaces/order-gift';

@Component({
    selector: 'app-gift-details',
    template: `
        <app-carousel [images]="order.gift.images" [imageBaseUri]="imagesUri" class="u-margin-bottom"></app-carousel>

        <div class="u-margin-bottom-double">
            <p class="u-color-muted u-font-weight-semi-bold u-margin-bottom-half">{{ 'PRODUCT' | translate }}</p>
            <p>{{ order.gift.nameLabels | translatedLabel | async }}</p>
        </div>

        <div class="u-margin-bottom-double" *ngIf="order.note">
            <p class="u-color-muted u-font-weight-semi-bold u-margin-bottom-half">{{ 'PARTNER.GIFT.NOTE' | translate }}</p>
            <app-copy-to-clipboard>{{ order.note }}</app-copy-to-clipboard>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftDetailsComponent {
    @Input() public order: OrderGift;

    public imagesUri = imagesUri;
}

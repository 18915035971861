import { Injectable } from '@angular/core';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { Observable } from 'rxjs';
import { ActivityLogService } from '../services/activity-log.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityLogSandbox {
    constructor(private activityLogService: ActivityLogService, private navigationSandbox: AppNavigationSandbox) {}

    public sendHeartbeat(): Observable<void> {
        return this.activityLogService.sendHeartbeat();
    }

    public trackPageView(): void {
        const route = this.navigationSandbox.getCurrentPath();
        this.activityLogService.sendPageView(route).subscribe();
    }
}

import { InjectionToken } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { Leaver } from '@app/move/enums/leaver.enum';
import { Transferee } from '@app/move/enums/transferee.enum';
import { Interviewee, RealEstateLevel } from '@smooved/core';
import { DurationFilter } from '../../../../../ui/src/lib/reviews/enums/duration-filter.enum';
import { QuestionValidatorType } from '../enums/question-validator-type.enum';
import { SurveyConfig } from '../interfaces/survey-config.interface';

export const defaultNpsLevel = RealEstateLevel.Group;
export const defaultDurationFilter = DurationFilter.AllTime;

export const SURVEY_CONFIG = new InjectionToken<SurveyConfig>('SURVEY_CONFIG');

export const mapInterviewee: { [key in Transferee | Leaver | null]: Interviewee } = {
    [Transferee.OwnerLandlord]: Interviewee.Landlord,
    [Transferee.OwnerTransferee]: Interviewee.Buyer,
    [Transferee.TenantTransferee]: Interviewee.Tenant,
    [Transferee.LandAgent]: null,
    [Leaver.OwnerLandlord]: Interviewee.Landlord,
    [Leaver.OwnerLeaver]: Interviewee.Seller,
    [Leaver.TenantLeaver]: null,
    [Leaver.LandAgent]: null,
};

export const mapQuestionValidator: { [key in QuestionValidatorType]: (value?: any) => ValidatorFn } = {
    [QuestionValidatorType.Required]: () => Validators.required,
    [QuestionValidatorType.Min]: (min: number) => Validators.min(min),
    [QuestionValidatorType.MinLength]: (minLength: number) => Validators.minLength(minLength),
    [QuestionValidatorType.Max]: (max: number) => Validators.max(max),
    [QuestionValidatorType.MaxLength]: (maxLength: number) => Validators.maxLength(maxLength),
    [QuestionValidatorType.Email]: () => Validators.email,
    [QuestionValidatorType.Pattern]: (pattern: string) => Validators.pattern(pattern),
};

export enum SurveyNavigation {
    Start = 'survey-start',
    SurveyStep = ':interview/:page',
}

<ng-container
    *ngIf="{
        isTabletPortraitUp: isTabletPortraitUp$ | async,
        hasContentAside: hasContentAside$ | async,
        hasContentAsideSticky: hasContentAsideSticky$ | async,
        hasFooter: hasFooter$ | async
    } as vm"
>
    <div class="__banner-img">
        <img [src]="externalInfluencer?.cover || companyPageCoverFallback" class="__cover-image" />
    </div>
    <div class="u-margin-0-auto __container">
        <ui-header-box [data]="headerBoxData" appInViewTrigger (visibleChanged)="onHeaderBoxVisibleChanged($event)"> </ui-header-box>

        <div
            #headerMenu
            class="__header-menu u-w100p u-padding-top u-padding-bottom u-padding-tablet-portrait-up"
            [class.__header-menu--sticky]="isStickyHeaderMenu"
        >
            <div class="u-w100p u-container-lg u-margin-0-auto">
                <ng-content select="[company-page-template-main-menu]"></ng-content>
            </div>
        </div>

        <div class="__content u-padding-top u-padding-tablet-portrait-up">
            <div
                class="__content__container u-container-lg u-margin-0-auto"
                [class.__content__container--has-aside]="vm.hasContentAside || vm.hasContentAsideSticky"
            >
                <main class="__main u-padding u-padding-double-tablet-portrait-up" [class.u-border-radius-large]="vm.isTabletPortraitUp">
                    <ng-content select="[company-page-main]"></ng-content>
                </main>
                <aside class="__aside">
                    <div #asideContent class="__aside__content" *ngIf="vm.hasContentAside">
                        <ng-content select="[company-page-aside]"></ng-content>
                    </div>
                    <div #asideContentSticky class="__aside__content--sticky" *ngIf="vm.hasContentAsideSticky">
                        <ng-content select="[company-page-aside-sticky]"></ng-content>
                    </div>
                </aside>
            </div>
        </div>
        <footer *ngIf="vm.hasFooter" class="u-background-white u-padding-tablet-portrait-up">
            <ng-content select="[company-page-footer]"></ng-content>
        </footer>
        <div *ngIf="!vm.isTabletPortraitUp" class="__company-page-template__footer __content u-padding">
            <ng-content select="[company-page-template-footer-menu]"></ng-content>
        </div>
    </div>
</ng-container>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { environment } from '@environments/environment';
import { zip } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CheckGuard implements CanActivate {
    constructor(private router: Router, private moveSandbox: MoveSandbox) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            zip(
                this.moveSandbox.realEstateGroupServicesInsurancesDisabledOnce$,
                this.moveSandbox.insurancesOfferSelectedOnce$,
                this.moveSandbox.insurancesOrderedOnce$
            )
                .pipe(take(1))
                .subscribe(([realEstateGroupServicesInsurancesDisabled, insurancesOfferSelected, insurancesOrdered]) => {
                    if (
                        !realEstateGroupServicesInsurancesDisabled &&
                        !insurancesOfferSelected &&
                        !insurancesOrdered &&
                        environment.services.iots
                    ) {
                        return resolve(true);
                    } else {
                        void this.router.navigateByUrl(`/${Navigation.Contract}`).then();
                        reject();
                    }
                });
        });
    }
}

<form [formGroup]="externalInfluencerService.form.get(formGroupNames.about)">
    <mat-tab-group
        class="mat-tab-group-theme mat-tab-group-theme--pill"
        animationDuration="0ms"
        [disableRipple]="true"
        mat-align-tabs="center"
    >
        <mat-tab *ngFor="let option of languageOptions" [label]="option.labelResource | translate">
            <ng-template matTabContent>
                <div [formGroupName]="formControlNames.about" class="u-margin-bottom">
                    <quill-editor
                        (onEditorCreated)="editorCreated($event)"
                        [styles]="{ height: '300px' }"
                        class="u-display-block"
                        [formControlName]="option.value"
                        [placeholder]="'PLACEHOLDER.TEXT.LABEL' | translate"
                        [modules]="editorModules"
                    ></quill-editor>
                </div>
                <app-select-input
                    [custom]="false"
                    [options]="[aboutDefaultLanguageOptions.get(option.value)]"
                    [formControlName]="formControlNames.aboutDefaultLanguage"
                ></app-select-input>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</form>

import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnDestroy, OnInit, Type, ViewContainerRef } from '@angular/core';
import { CellTemplateComponent } from './cell-template.component';
import { RowDef } from './table.interfaces';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[uiCellTemplateLoader]' })
export class CellTemplateLoaderDirective implements OnInit, OnDestroy {
    @Input() template: Type<CellTemplateComponent>;
    @Input() row: RowDef<any>;

    private cellTemplateRef: ComponentRef<CellTemplateComponent>;

    constructor(private viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}

    public ngOnInit(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.template);
        this.cellTemplateRef = this.viewContainerRef.createComponent(componentFactory);
        this.cellTemplateRef.instance.uiInit(this.row);
    }

    public ngOnDestroy(): void {
        if (this.viewContainerRef) {
            this.cellTemplateRef.destroy();
        }
    }
}

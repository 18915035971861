import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { MetaConfig } from './meta-config';

@Injectable()
export abstract class BaseMetaService {
    protected renderer: Renderer2;

    protected constructor(
        protected readonly meta: Meta,
        protected readonly title: Title,
        @Inject(DOCUMENT) protected document: Document,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public set(config: MetaConfig) {
        const { title, description, image, url } = config;
        this.title.setTitle(title);
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.addTags([
            ...this.titleFactory(title),
            ...this.descriptionFactory(description),
            ...this.typeFactory(),
            ...this.imageFactory(image),
            ...this.urlFactory(url),
        ]);
    }

    public addCanonicalLink(href: string): void {
        this.cleanUpOldElements('link[rel="canonical"]');
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', href);
        this.renderer.appendChild(this.document.head, link);
    }

    public addAlternateLangLink(hreflang: string, href: string): void {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'alternate');
        this.renderer.setAttribute(link, 'hreflang', hreflang);
        this.renderer.setAttribute(link, 'href', href);
        this.renderer.appendChild(this.document.head, link);
    }

    public addGoogleRatingScript(scriptValue: string): void {
        const existingScript = this.document.head.querySelector('script[type="application/ld+json"]');

        if (existingScript) {
            this.renderer.setProperty(existingScript, 'innerHTML', scriptValue);
        } else {
            const script = this.renderer.createElement('script');
            this.renderer.setAttribute(script, 'type', 'application/ld+json');
            script.textContent = scriptValue;
            this.renderer.appendChild(this.document.head, script);
        }
    }

    public addMetaTags(object: { [key: string]: string }) {
        Object.keys(object).forEach((key) => {
            this.meta.updateTag({ property: key, content: object[key] });
        });
    }

    public cleanUpOldElements(selector: string): void {
        const oldElementsList = this.document.head.querySelectorAll(selector);
        oldElementsList.forEach((oldElement) => {
            this.renderer.removeChild(this.document.head, oldElement);
        });
    }

    public addNoIndexMetaTag() {
        this.meta.updateTag({ name: 'robots', content: 'noindex' });
    }

    public removeNoIndexMetaTag() {
        this.meta.removeTag('name="robots"');
    }

    protected abstract buildMeta(...args: any[]): {
        [key: string]: string;
    };

    protected titleFactory(value: string): MetaDefinition[] {
        if (!value) return [];
        return [
            { property: 'og:title', content: value },
            { property: 'twitter:title', content: value },
        ];
    }

    protected descriptionFactory(value: string): MetaDefinition[] {
        if (!value) return [];
        return [
            { property: 'og:description', content: value },
            { property: 'twitter:description', content: value },
        ];
    }

    protected imageFactory(value: string): MetaDefinition[] {
        if (!value) return [];
        return [
            { property: 'og:image', content: value },
            { property: 'twitter:image', content: value },
        ];
    }

    protected typeFactory(): MetaDefinition[] {
        return [{ property: 'og:type', content: 'website' }];
    }

    protected urlFactory(value: string): MetaDefinition[] {
        if (!value) return [];
        return [
            { property: 'og:url', content: value },
            { property: 'twitter:url', content: value },
        ];
    }
}

import { DateTimeInputOptions } from '@app/form/components/date-time-input/date-time-input-options';

export const defaultOptions: DateTimeInputOptions = {
    showSpinners: true,
    showSeconds: false,
    stepHour: 1,
    stepMinute: 1,
    stepSecond: 1,
    touchUi: false,
    disableMinute: false,
    hideTime: false,
};

export const defaultTime = [0, 0, 0];

import { Navigation } from '@app/navigation/enums/navigation.enum';
import { SettingsDetailsMenuItem } from './settings-details-menu.interface';
import { FeatureScope } from '@smooved/core';

export const menuItems = new Map<FeatureScope | string, SettingsDetailsMenuItem>()
    .set(FeatureScope.Reviews, {
        labelResource: 'REVIEWS.LABEL_PLURAL',
        goTo: Navigation.Reviews,
        featureScope: FeatureScope.Reviews,
    })
    .set(FeatureScope.Gift, {
        labelResource: 'GIFTS.LABEL_PLURAL',
        goTo: Navigation.Gifts,
        featureScope: FeatureScope.Gift,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.Mortgage, {
        labelResource: 'MORTGAGE.LABEL_PLURAL',
        goTo: Navigation.Mortgage,
        featureScope: FeatureScope.Mortgage,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.Insurances, {
        labelResource: 'INSURANCES.LABEL_PLURAL',
        goTo: Navigation.Insurances,
        featureScope: FeatureScope.Insurances,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.Water, {
        labelResource: 'WATER.LABEL',
        goTo: Navigation.Water,
        featureScope: FeatureScope.Water,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.Tots, {
        labelResource: 'TOTS.LABEL',
        goTo: Navigation.Tots,
        featureScope: FeatureScope.Tots,
        hide: FeatureScope.Transactions,
    })
    .set(FeatureScope.RentalInspection, {
        labelResource: 'RENTAL_INSPECTION.LABEL_PLURAL',
        goTo: Navigation.RentalInspection,
        featureScope: FeatureScope.RentalInspection,
        hide: FeatureScope.Transactions,
    });

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormModule } from '@app/form/form.module';
import { PackageProvidersModule } from '@app/package/package-providers.module';
import { BivAgentFormModalComponent } from '@app/real-estate-group/modals/biv-agent-form/biv-agent-form.modal';
import { EditPackageModal } from '@app/real-estate-group/modals/edit-package/edit-package.modal';
import { OfficeFormModal } from '@app/real-estate-group/modals/office-form/office-form.modal';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsUriConfig, ANALYTICS_URI_CONFIG, ButtonModule, LoadingModule, UiFormModule, UiModalModule } from '@smooved/ui';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../../../projects/external-influencer/src/environments/environment';
import { BillingSettingsComponent } from './components/billing-settings/billing-settings.component';
import { UpdatePropertyComponent } from './components/update-property/update-property.component';
import { LocationFormModal } from './modals/location-form/location-form.modal';
import { RealEstateGroupProvidersModule } from './real-estate-group-providers.module';
import { RealEstateGroupEffects } from './state/real-estate-group.effects';
import { realEstateGroupReducer, storeFeatureName } from './state/real-estate-group.reducer';

const components = [EditPackageModal];
const componentsToExport = [
    LocationFormModal,
    OfficeFormModal,
    UpdatePropertyComponent,
    BivAgentFormModalComponent,
    BillingSettingsComponent,
];

@NgModule({
    declarations: [...components, ...componentsToExport],
    exports: [...componentsToExport],
    imports: [
        CommonModule,
        TranslateModule,
        UiModalModule,
        FormModule,
        ButtonModule,
        LoadingModule,
        UiFormModule,
        PackageProvidersModule,
        StoreModule.forFeature(storeFeatureName, realEstateGroupReducer),
        EffectsModule.forFeature([RealEstateGroupEffects]),
        NgxMaskModule.forChild(),
        RealEstateGroupProvidersModule,
    ],
    providers: [{ provide: ANALYTICS_URI_CONFIG, useValue: new AnalyticsUriConfig(environment.apiUri) }],
})
export class RealEstateGroupModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { GalleryModule } from 'ng-gallery';
import { CarouselComponent } from './components/carousel/carousel.component';

@NgModule({
    declarations: [CarouselComponent],
    imports: [CommonModule, GalleryModule, MatDialogModule],
    exports: [CarouselComponent],
})
export class CarouselModule {}

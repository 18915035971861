import { ValidatorFn, Validators } from '@angular/forms';
import { Leaver } from '@app/move/enums/leaver.enum';
import { Transferee } from '@app/move/enums/transferee.enum';
import { AuthenticatedInterviewee, EnumUtils, I18nKeyType, Interviewee, RealEstateLevel, StringUtils } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';
import { DurationFilter } from '../../../../projects/ui/src/lib/reviews/enums/duration-filter.enum';
import { i18nKeyTypeMap } from '../../shared/constants/i18n-key-type-map';
import { QuestionValidatorType } from '../enums/question-validator-type.enum';

export const defaultNpsLevel = RealEstateLevel.Group;
export const defaultDurationFilter = DurationFilter.AllTime;
export const houseMatchCompanyPageReviewUrl = ':baseUrl/:lang/:realEstateGroup';

export const mapInterviewee: { [key in Transferee | Leaver | null]: Interviewee } = {
    [Transferee.OwnerLandlord]: Interviewee.Landlord,
    [Transferee.OwnerTransferee]: Interviewee.Buyer,
    [Transferee.TenantTransferee]: Interviewee.Tenant,
    [Transferee.LandAgent]: null,
    [Leaver.OwnerLandlord]: Interviewee.Landlord,
    [Leaver.OwnerLeaver]: Interviewee.Seller,
    [Leaver.TenantLeaver]: null,
    [Leaver.LandAgent]: null,
};

export const assessedOptions: DropdownInput<AuthenticatedInterviewee>[] = EnumUtils.entries(AuthenticatedInterviewee).map(
    (item: any, key) => ({
        id: `authenticated-interviewee-${key}`,
        labelResource: `${i18nKeyTypeMap[I18nKeyType.Interviewee]}.${StringUtils.toUpperSnakeCase(item[0])}`,
        name: 'authenticated-interviewee',
        value: item[1],
    })
);

export const mapQuestionValidator: { [key in QuestionValidatorType]: (value?: any) => ValidatorFn } = {
    [QuestionValidatorType.Required]: () => Validators.required,
    [QuestionValidatorType.Min]: (min: number) => Validators.min(min),
    [QuestionValidatorType.MinLength]: (minLength: number) => Validators.minLength(minLength),
    [QuestionValidatorType.Max]: (max: number) => Validators.max(max),
    [QuestionValidatorType.MaxLength]: (maxLength: number) => Validators.maxLength(maxLength),
    [QuestionValidatorType.Email]: () => Validators.email,
    [QuestionValidatorType.Pattern]: (pattern: string) => Validators.pattern(pattern),
};

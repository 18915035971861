import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { NAVIGATE_TO_ROOT } from '@smooved/core';
import { Observable, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConfirmGuard implements CanActivate {
    constructor(private router: Router, private moveSandbox: MoveSandbox) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return zip(
            this.moveSandbox.canOrder$({ energy: true }),
            this.moveSandbox.energyOfferSelection$,
            this.moveSandbox.energyTransfer$,
            this.moveSandbox.canOrder$({ telecom: true }),
            this.moveSandbox.telecomOfferSelection$,
            this.moveSandbox.telecomTransfer$
        ).pipe(
            take(1),
            map(([canOrderEnergy, energyOfferSelection, energyTransfer, canOrderTelecom, telecomOfferSelection, telecomTransfer]) => {
                const hasEnergy = canOrderEnergy && (!!energyOfferSelection || !!energyTransfer);
                const hasTelecom = canOrderTelecom && (!!telecomOfferSelection || !!telecomTransfer);
                if (hasEnergy || hasTelecom) {
                    return true;
                } else {
                    return this.router.createUrlTree([...NAVIGATE_TO_ROOT, Navigation.Dashboard]);
                }
            })
        );
    }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimpleChangesUtils } from '@smooved/core';
import { ModalSandbox } from '@smooved/ui';
import { GalleryItem, ImageItem } from 'ng-gallery';

@Component({
    selector: 'app-carousel',
    template: ` <gallery
        [items]="items"
        [thumb]="items?.length > 1"
        [counter]="false"
        thumbHeight="60"
        (itemClick)="onItemClick($event)"
    ></gallery>`,
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnChanges {
    @Input() public images: string[];
    @Input() public imageBaseUri: string;

    public items: GalleryItem[] = [];

    constructor(private readonly modalSandbox: ModalSandbox) {}

    public ngOnChanges({ images, imageBaseUri }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(images) || SimpleChangesUtils.hasChanged(imageBaseUri)) {
            this.handleImagesChanged();
        }
    }

    public onItemClick(index: number): void {
        this.modalSandbox.showImageModal(this.items[index].data?.src);
    }

    private handleImagesChanged(): void {
        if (!this.images.length || !this.imageBaseUri) {
            this.items = [];
        }
        this.items = this.images.map(
            (image) =>
                new ImageItem({
                    src: `${this.imageBaseUri}/${image}`,
                    thumb: `${this.imageBaseUri}/${image}`,
                })
        );
    }
}

import { Injectable } from '@angular/core';
import { DbUtils, Me, PostHogEventsEnum } from '@smooved/core';
import posthog from 'posthog-js';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';

@Injectable({ providedIn: 'root' })
export class PosthogService {
    constructor(private readonly realEstateGroupSandbox: RealEstateGroupSandbox) {}

    public sendEventForTransaction(event: PostHogEventsEnum, transactionId: string): void {
        if (!event) return;
        this.realEstateGroupSandbox.realEstateGroupOnce$.subscribe((realEstateGroup) => {
            posthog.capture(event, {
                transactionId,
                realEstateGroupId: DbUtils.getStringId(realEstateGroup),
                officeId: (realEstateGroup as unknown as Me)?.officeId,
                realEstateAgentId: (realEstateGroup as unknown as Me)?.realEstateAgentId,
            });
        });
    }
}

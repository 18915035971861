import { Component } from '@angular/core';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { StorageSandbox } from '@app/storage/sandboxes/storage.sandbox';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthenticationSandbox } from '../../sandboxes/authentication.sandbox';
import { LocalStorageKeys } from '../../../storage/sandboxes/storage.constants';

@Component({
    selector: 'app-admin-login-container',
    template: `
        <div class="u-padding-top-double u-flex-row u-flex-justify-content-center">
            <app-admin-login (formSubmit)="adminLogin($event)"></app-admin-login>
        </div>
    `,
    styles: [],
})
export class AdminLoginContainer {
    constructor(
        private authenticationSandbox: AuthenticationSandbox,
        public navigationSandbox: AppNavigationSandbox,
        private storageSandbox: StorageSandbox
    ) {}

    public adminLogin(event): void {
        this.authenticationSandbox
            .userLogin(event)
            .pipe(
                tap((_) => this.storageSandbox.deleteToken(LocalStorageKeys.Language)), //delete any cookie language storage in order to get logged in user language
                switchMap((_) => this.authenticationSandbox.authorization()),
                tap((authorization) => {
                    this.authenticationSandbox.setAuthorization(authorization);
                    void this.navigationSandbox.goToHome().then();
                }),
                catchError((_) => {
                    this.authenticationSandbox.isLoggedInOnce$.subscribe((isLoggedIn) => {
                        if (isLoggedIn) void this.navigationSandbox.goToHome().then();
                    });
                    return of();
                })
            )
            .subscribe();
    }
}

import { Component } from '@angular/core';
import {
    aboutDefaultLanguageOptions,
    editorModules,
    languageOptions,
} from '@app/external-influencer/components/external-influencer-configure-about/external-influencer-configure-about.constants';
import { formControlNames, formGroupNames } from '@app/external-influencer/constants/form.constants';
import { ExternalInfluencerService } from '@app/external-influencer/services/external-influencer.service';
import { QuillUtils } from '@smooved/ui';
import Quill from 'quill';

@Component({
    selector: 'app-external-influencer-configure-about',
    templateUrl: './external-influencer-configure-about.component.html',
})
export class ExternalInfluencerConfigureAboutComponent {
    public formGroupNames = formGroupNames;
    public formControlNames = formControlNames;
    public languageOptions = languageOptions;
    public aboutDefaultLanguageOptions = aboutDefaultLanguageOptions;
    public editorModules = editorModules;

    constructor(public readonly externalInfluencerService: ExternalInfluencerService) {}

    public editorCreated(event: Quill): void {
        QuillUtils.addMatcherPastePlainText(event);
    }
}

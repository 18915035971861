<ng-container *ngIf="!disabled && !readonly; else itemDisabled">
    <ng-container *ngIf="!!goTo; else noLink">
        <div
            class="__menu-item__container u-flex u-w100p"
            [class.__tag]="!!tag"
            [matBadge]="tag || badge"
            [matBadgeHidden]="!(badge || tag)"
            [matBadgeColor]="!!badge ? 'warn' : ''"
            [matBadgeWide]="!!tag || isWide"
            [badgeVerticalAlign]="badgeAlignment"
        >
            <a
                [routerLink]="goTo | menuItemRoute"
                [routerLinkActiveOptions]="{ exact: goTo === './' ? true : false }"
                routerLinkActive="is-active"
                [ngClass]="appearanceClass"
                [title]="title || ''"
            >
                <div
                    *ngIf="!disabled && !readonly && hasSubMenu; else noSubmenu"
                    class="u-w100p u-flex-row u-flex-align-items-center u-flex-justify-content-space-between"
                >
                    <ng-container *ngTemplateOutlet="body"></ng-container>
                    <app-svg-illustration
                        class="__illustration __menu-item-mobile-navigate-icon"
                        [svg]="illustrationEnum.ChevronRight"
                    ></app-svg-illustration>
                </div>
                <ng-template #noSubmenu>
                    <ng-container *ngTemplateOutlet="body"></ng-container>
                </ng-template>
            </a>
        </div>
    </ng-container>
</ng-container>

<ng-template #noLink>
    <div
        class="__menu-item__container u-flex u-w100p"
        [class.__tag]="!!tag"
        [matBadge]="tag || badge"
        [matBadgeHidden]="!(badge || tag)"
        [matBadgeColor]="!!badge ? 'warn' : ''"
        [matBadgeWide]="!!tag || isWide"
        [badgeVerticalAlign]="badgeAlignment"
    >
        <a *ngIf="!anchor; else anchorLink" [ngClass]="appearanceClass" [class.is-active]="active" [title]="title || ''">
            <ng-container *ngTemplateOutlet="body"></ng-container>
        </a>
    </div>
    <ng-container *ngTemplateOutlet="tag"></ng-container>
</ng-template>

<ng-template #itemDisabled>
    <div
        class="__menu-item__container __menu-item--disabled"
        [class.__menu-item--readonly]="readonly"
        [ngClass]="appearanceClass"
        [title]="title || ''"
    >
        <ng-container *ngTemplateOutlet="body"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="badgeTag"></ng-container>
</ng-template>

<ng-template #body>
    <app-icon *ngIf="icon" [icon]="icon" class="__menu-icon" [rotate]="iconRotate" size="xm"></app-icon>
    <app-svg-illustration class="__illustration u-flex-no-shrink" *ngIf="svg" [svg]="svg"></app-svg-illustration>
    <div class="__menu-item-label u-w100p" #label>
        <ng-content></ng-content>
        <div class="u-font-size-small u-font-weight-normal u-color-muted">
            <ng-content select="[menu-item-caption]"></ng-content>
        </div>
    </div>

    <app-icon
        *ngIf="!disabled && !readonly && !menuAppearance.Link"
        class="__menu-item-mobile-navigate-icon u-color-gray-light"
        icon="chevron_right"
        [size]="iconSize.Inherit"
    ></app-icon>
</ng-template>

<ng-template #badgeTag>
    <div class="__tag" [matBadge]="tag" matBadgeWide="!!tag" [badgeVerticalAlign]="badgeAlignment" [matBadgeHidden]="!tag"></div>
</ng-template>

<ng-template #anchorLink>
    <a [ngClass]="appearanceClass" [class.is-active]="active" [title]="title || ''" (click)="scrollTo($event)">
        <ng-container *ngTemplateOutlet="body"></ng-container>
    </a>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactDetailsLeaverContainer } from '@app/energy-contract/containers/contact-details-leaver/contact-details-leaver.container';
import { SuccessContainer } from '@app/energy-contract/containers/success/success.container';
import { AppFeatureScopeGuard } from '@app/feature-scope/guards/app-feature-scope-guard.service';
import { MoveStateDeactivator } from '@app/move/guards/move-state.deactivator';
import { analyticsRouting } from '@app/real-estate-agent/constants/routing/analytics-routing.constant';
import { financialRouting } from '@app/real-estate-agent/constants/routing/financial-routing.constant';
import { officesRouting } from '@app/real-estate-agent/constants/routing/offices-routing.constant';
import { OfficeContainer } from '@app/real-estate-agent/containers/office/office.container';
import { FeatureScope, LogicalOperation, NavigationData } from '@smooved/core';
import { Navigation, NavigationOutlet } from '../navigation/enums/navigation.enum';
import { DashboardMenuComponent } from './components/dashboard-menu/dashboard-menu.component';
import { ManagementMenuComponent } from './components/management-menu/management-menu.component';
import { MetricsMenuComponent } from './components/metrics-menu/metrics-menu.component';
import { ReviewsMenuComponent } from './components/reviews-menu/reviews-menu.component';
import { modulesRouting } from './constants/routing/modules-routing.constants';
import { reviewsRouting } from './constants/routing/reviews-routing.constant';
import { DashboardContainerComponent } from './containers/dashboard/dashboard.container';
import { MortgageOverviewContainer } from './containers/mortgage-overview/mortgage-overview.container';
import { RealEstateAgentStartContainer } from './containers/real-estate-agent-start/real-estate-agent-start.container';
import { StartContainer } from './containers/start/start.container';
import { BadgeCountActivator } from './guards/badge-count-activator.guard';

export const realEstateAgentRoutes: Routes = [
    {
        path: '',
        canActivate: [BadgeCountActivator],
        canDeactivate: [BadgeCountActivator],
        children: [
            {
                path: Navigation.RealEstateAgentDashboard,
                canActivate: [MoveStateDeactivator, AppFeatureScopeGuard],
                children: [
                    {
                        path: '',
                        component: DashboardMenuComponent,
                        outlet: NavigationOutlet.Secondary,
                    },
                    {
                        path: '',
                        component: DashboardContainerComponent,
                    },
                    {
                        path: ':id',
                        component: DashboardContainerComponent,
                    },
                ],
                data: {
                    [NavigationData.ShowSideBar]: true,
                    [NavigationData.FeatureScopeConfig]: { scopes: [FeatureScope.Transactions], operator: LogicalOperation.And },
                },
            },
            {
                path: Navigation.RealEstateAgentStart,
                component: StartContainer,
                canActivate: [MoveStateDeactivator],
                data: { [NavigationData.ShowBeamer]: true, [NavigationData.ShowSideBar]: true },
            },
            {
                path: Navigation.RealEstateAgentRegisterMove,
                component: RealEstateAgentStartContainer,
                canActivate: [AppFeatureScopeGuard],
                data: {
                    [NavigationData.FeatureScopeConfig]: { scopes: [FeatureScope.Transactions], operator: LogicalOperation.And },
                },
            },
            {
                path: Navigation.Reviews,
                children: [
                    {
                        path: '',
                        component: ReviewsMenuComponent,
                        outlet: NavigationOutlet.Secondary,
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: Navigation.Overview,
                    },
                    ...reviewsRouting,
                ],
                data: {
                    [NavigationData.ShowSideBar]: true,
                },
            },
            {
                path: Navigation.Metrics,
                canActivate: [AppFeatureScopeGuard],
                children: [
                    {
                        path: '',
                        component: MetricsMenuComponent,
                        outlet: NavigationOutlet.Secondary,
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: Navigation.Analytics,
                    },
                    analyticsRouting,
                ],
                data: {
                    [NavigationData.ShowSideBar]: true,
                    [NavigationData.FeatureScopeConfig]: { scopes: [FeatureScope.Transactions], operator: LogicalOperation.And },
                },
            },
            {
                path: Navigation.Management,
                children: [
                    {
                        path: '',
                        component: ManagementMenuComponent,
                        outlet: NavigationOutlet.Secondary,
                    },
                    {
                        path: Navigation.Team,
                        component: OfficeContainer,
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: Navigation.Team,
                    },
                    modulesRouting,
                    officesRouting,
                    financialRouting,
                ],
                data: {
                    [NavigationData.ShowSideBar]: true,
                },
            },
            {
                path: Navigation.Mortgage,
                canActivate: [AppFeatureScopeGuard],
                data: {
                    [NavigationData.FeatureScopeConfig]: { scopes: [FeatureScope.Mortgage], operator: LogicalOperation.And },
                },
                children: [
                    {
                        path: Navigation.Overview,
                        component: MortgageOverviewContainer,
                    },
                ],
            },
            {
                path: Navigation.ContactDetailsLeaver,
                component: ContactDetailsLeaverContainer,
            },
            {
                path: 'contract-success',
                component: SuccessContainer,
            },
            {
                path: '**',
                redirectTo: Navigation.RealEstateAgentStart,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(realEstateAgentRoutes)],
    exports: [RouterModule],
})
export class RealEstateAgentRoutingModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationSandbox } from '../../../authentication/sandboxes/authentication.sandbox';
import { AppNavigationSandbox } from '../../../navigation/sandboxes/navigation.sandbox';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanLoad, CanActivate {
    constructor(private authenticationSandbox: AuthenticationSandbox, private navigationSandbox: AppNavigationSandbox) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.checkAccess();
    }

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAccess();
    }

    private checkAccess(): Observable<boolean> {
        return this.authenticationSandbox.isAdmin$.pipe(
            map((isAdmin) => {
                if (!isAdmin) {
                    this.navigationSandbox.goToHome().then();
                    return false;
                }
                return true;
            }),
            take(1)
        );
    }
}
